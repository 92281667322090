<template>
  <OnboardPage 
    :content="content"
  >  
    <Notification
      v-if="error"
      type="danger"
    >
      {{ error }}
    </Notification>
    <form @submit.prevent="onSubmit">
      <BaseInput
        label="Phone"
        v-model.trim="$v.business.phone.$model"
        :validation="$v.business.phone"
        :mask="'(###)###-####'"
      />
      <BaseInput
        label="Website (optional)"
        v-model.trim="$v.business.website.$model"
        :validation="$v.business.website"
      />
      <BaseSubmit
         v-if="content.showNext" 
        :label="content.showNextLabel"
        :disabled="$v.$invalid"
        :submitting="submitting"
      />
    </form>
    <div
     style="margin-top:20px;"
    >
      <a
        v-if="content.showBack" 
        class="is-size-7 has-text-grey"
        @click="back()"
      >
        &larr;
        back
      </a>
    </div>
  </OnboardPage>
</template>

<script>
import {AuthError, NetworkError} from "@/errors";
import { minLength, required } from 'vuelidate/lib/validators';
import OnboardPage from "@/components/OnboardPage.vue";
import Notification from "@/components/Notification.vue";
import BaseInput from "@/components/form/BaseInput.vue";
import BaseSubmit from "@/components/form/BaseSubmit.vue";

export default {
  components: {
    OnboardPage,
    Notification,
    BaseInput,
    BaseSubmit
  },
  data() {
    return {
      loading: false,
      error: null,
      submitting: false,
      business: {
        website: '',
        phone: ''
      },
      content: {
        page: 'organization-contact',
        step: 2,
        title: 'Contact information',
        subtitle: 'Help us help customers find you.',
        nextPage: "organization-image",
        sideComponentText: 'Help us help customers find you.',
        showNextLabel: "Next",
        showNext: true,
        showBack: true
      }
    }
  },
  validations: {
    business: {
      website: { },
      phone: { required, minLength: minLength(13) }
    }
  },
  computed: {
    currentUser: function() {
      return this.$store.getters.currentUser;     
    },
    currentBusiness: function() {
      return this.$store.getters.currentBusiness;     
    },
  },
  mounted() {
    if (this.currentBusiness && this.currentBusiness.slug) {
      
      if (this.currentBusiness.is_onboarded == true) {
        this.$router.push('/deals/user/my-location/100')
      } else {
        this.business = this.currentBusiness
      }  

    } else {
      this.$router.push("/o/organization-new")
    } 
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    onSubmit() {
      this.submitting = true;
      this.$v.$touch();
      if (this.$v.$invalid) return;
      const params = {
        user_slug: this.currentUser.slug,
        slug: this.currentBusiness.slug,
        organization: this.business
      }
      this.$store.dispatch("updateBusiness", params)
        .then(() => {
          this.submitting = false
          this.$router.push('/o/' + this.content.nextPage)
        })
        .catch(error => {
          if (error instanceof AuthError || error instanceof NetworkError) {
            throw error
          } else {
            console.log(error)
            this.error = error.message
          }
        })
    }
  }
}
</script>
